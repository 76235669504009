export enum AssetsViewType {
	map = 'map',
	card = 'card',
	list = 'list',
}

export const UNSUPPORTED_MOBILE_VIEW_TYPES = new Set([AssetsViewType.map, AssetsViewType.list]);

export enum AssetSystemFolder {
	all = 'all',
	archived = 'archived',
	favourite = 'favourite',
	demo = 'demo',
}

export enum SortType {
	lastModified = 'lastModified',
	name = 'name',
	lastCreated = 'lastCreated',
	rating = 'rating',
}
