import { matchPath, useRouteMatch } from 'react-router-dom';
import memoizeOne from 'memoize-one';
import { RootState } from '../../redux/reducers';
import { PROJECTS_OVERVIEW, PROJECTS_WITH_ID } from '../../components/Routes/urls';
import { getSessionItem, removeSessionItem, saveSessionItem } from '../browser/storageUtils';
import { AssetsViewType } from '../../tstypes/projects';
import * as routeUrls from '../../components/Routes/urls';

export const getCurrentPath = (state: RootState) => (state.router ? state.router.location.pathname : '');

export type TypeParams<T> = { [K in keyof T]?: string };

export const parseQuery = memoizeOne(<T = any>(queryString: string, decodeURI: boolean = true): TypeParams<T> => {
	const query: TypeParams<T> = {};
	const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
	for (let i = 0; i < pairs.length; i++) {
		const pair = pairs[i].split('=');
		const key = (decodeURI ? decodeURIComponent(pair[0]) : pair[0]) as keyof T;
		query[key] = decodeURI ? decodeURIComponent(pair[1] ?? '') : pair[1] ?? '';
	}
	return query;
});

export const makeQueryPath = (
	path: string,
	query: { [key: string]: string | number | boolean | undefined },
	encode: boolean = true
): string => {
	const keys = Object.keys(query).filter((key) => query[key]);
	if (keys.length === 0) {
		return path;
	}
	return keys.reduce((newPath, key, i) => {
		const value = encode ? window.encodeURIComponent(query[key] ?? '') : query[key];
		return `${newPath}${key}=${value}${i < keys.length - 1 ? '&' : ''}`;
	}, `${path}?`);
};

export const removeQueryKeys = (path: string, queryString: string, keys: string[]): string => {
	const query = parseQuery<any>(queryString, false);
	keys.forEach((key) => {
		delete query[key];
	});
	return makeQueryPath(path, query, false);
};

interface ProjectParams {
	companyID?: string;
	projectID?: string;
}

export const useProjectParams = (): ProjectParams => {
	const match = useRouteMatch(PROJECTS_WITH_ID);
	const { companyID, projectID } = (match?.params as { companyID?: string; projectID?: string }) || {};

	return {
		companyID,
		projectID,
	};
};

export const useAssetViewType = () => {
	const match = useRouteMatch(PROJECTS_OVERVIEW);
	const { viewType } = (match?.params as { viewType: string }) || {};
	return viewType;
};

export const getCompanyID = (pathname: string): string | null => {
	const paths = pathname?.split('/');
	if (paths?.length > 3 && paths[1] === 'company') {
		return paths[2];
	}
	return null;
};

export const getProjectID = (pathname: string): string | null => {
	const paths = pathname?.split('/');
	if (paths?.length >= 4 && paths[3] === 'projects') {
		const projectID = paths[4];
		// possible that it's project overview with path /company/:companyID/projects/:AssetsViewType/:folderID
		return Object.values<string>(AssetsViewType).includes(projectID) ? null : projectID;
	}
	return null;
};

export const saveLandingPath = () => {
	if (location.pathname && !getSessionItem('landingPathname')) {
		saveSessionItem('landingPathname', location.pathname);
	}
	if (location.search && !getSessionItem('landingQuery')) {
		saveSessionItem('landingQuery', location.search);
	}
};

const getLandingPath = () => {
	const landingPathname = getSessionItem('landingPathname') ?? '';
	const landingQuery = getSessionItem('landingQuery') ?? '';
	return landingPathname ? landingPathname + landingQuery : '';
};

export const restoreLandingPath = () => {
	const landingPath = getLandingPath();
	removeSessionItem('landingPathname');
	removeSessionItem('landingQuery');
	return landingPath;
};

export const getSharedLinkLandingPath = () => {
	const landingPath = getLandingPath();
	const match = matchPath(landingPath, { path: routeUrls.LINK_SHARING });
	return match ? landingPath : undefined;
};
